// Indian User
// Phone => Phone Number > Otp verification > User details > Create Account
// Google => Google Login > User details (incl. phone) > Otp verification > Create account

// International user -
// Email => Email > Otp verification > User Details > Create Account
// Google => Google login > User Details > Create Account

const LOGIN_METHOD = {
    PHONE: 'phone',
    EMAIL: 'email',
    SOCIAL: 'social'
};

const AUTH_FLOW = {
    INDIAN_PHONE: 'INDIAN_PHONE',
    INDIAN_GOOGLE: 'INDIAN_GOOGLE',
    INTERNATIONAL_PHONE: 'INTERNATIONAL_PHONE',
    INTERNATIONAL_EMAIL: 'INTERNATIONAL_EMAIL',
    INTERNATIONAL_GOOGLE: 'INTERNATIONAL_GOOGLE'
};

const AUTH_STEP = {
    PHONE_INPUT: 'PHONE_INPUT',
    EMAIL_INPUT: 'EMAIL_INPUT',
    GOOGLE_SIGNIN: 'GOOGLE_SIGNIN',
    OTP_VERIFICATION: 'OTP_VERIFICATION',
    USER_AND_EXAM_DETAILS: 'USER_AND_EXAM_DETAILS',
    EXAM_DETAILS: 'EXAM_DETAILS'
};

const INDIAN_PHONE = {
    authFlow: AUTH_FLOW.INDIAN_PHONE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.PHONE_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_EXAM_DETAILS
        }
    ]
};

const INDIAN_GOOGLE = {
    authFlow: AUTH_FLOW.INDIAN_GOOGLE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.GOOGLE_SIGNIN
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.EXAM_DETAILS
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.OTP_VERIFICATION
        }
    ]
};

const INTERNATIONAL_PHONE = {
    authFlow: AUTH_FLOW.INTERNATIONAL_PHONE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.PHONE_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_EXAM_DETAILS
        }
    ]
};

const INTERNATIONAL_EMAIL = {
    authFlow: AUTH_FLOW.INTERNATIONAL_EMAIL,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.EMAIL_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_EXAM_DETAILS
        }
    ]
};

const INTERNATIONAL_GOOGLE = {
    authFlow: AUTH_FLOW.INTERNATIONAL_GOOGLE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.GOOGLE_SIGNIN
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.EXAM_DETAILS
        }
    ]
};

const ACQUISITION_SOURCE_CTA = Object.freeze({
    home_start_free_trial: 'home_start_free_trial',
    home_gre_diag_test: 'home_gre_diag_test',
    start_gre_test: 'start_gre_test',
    study_planner_get_started: 'study_planner_get_started',
    vocab_start_practice: 'vocab_start_practice',
    gre_live_enroll_now: 'gre_live_enroll_now',
    on_demand_enroll_now_hero: 'on_demand_enroll_now_hero',
    on_demand_enroll_now: 'on_demand_enroll_now',
    gre_guide_start_free_trial: 'gre_guide_start_free_trial',
    gmat_guide_signup: 'gmat_guide_signup',
    gre_blog_start_free_trial: 'gre_blog_start_free_trial',
    gmat_blog_signup: 'gmat_blog_signup',
    pricing_prep_plus_practice: 'pricing_prep_plus_practice',
    pricing_prep_plus_on_demand: 'pricing_prep_plus_on_demand',
    pricing_prep_plus_live: 'pricing_prep_plus_live',
    pricing_prep_plus_practice_plans: 'pricing_prep_plus_practice_plans',
    pricing_prep_plus_on_demand_plans: 'pricing_prep_plus_on_demand_plans',
    pricing_prep_plus_live_plans: 'pricing_prep_plus_live_plans',
    pricing_start_free_trial: 'pricing_start_free_trial',
    prep_gauth_popup: 'prep_gauth_popup',
    dismissable_redirection_modal: 'dismissable_redirection_modal',
    non_dismissable_redirection_modal: 'non_dismissable_redirection_modal',
    nav_signup: 'nav_signup'
});

export {
    LOGIN_METHOD,
    AUTH_FLOW,
    AUTH_STEP,
    INDIAN_PHONE,
    INDIAN_GOOGLE,
    INTERNATIONAL_PHONE,
    INTERNATIONAL_EMAIL,
    INTERNATIONAL_GOOGLE,
    ACQUISITION_SOURCE_CTA
};
