//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ModalContainer',
    props: {
        // if centerPositioned, the modal won't come down on pwa as a drawer.
        centerPositioned: {
            type: Boolean,
            default: false
        },
        blurBg: {
            type: Boolean,
            default: false
        },
        bgColor: {
            type: String,
            default: 'bg-black'
        },
        bgOpacity: {
            type: String,
            default: 'opacity-20'
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
    }
};
